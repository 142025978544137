/* istanbul ignore file */
import { useOktaAuth } from '@okta/okta-react';
import { Cookies } from 'react-cookie';
import { React,useEffect , useState} from 'react';
import {envConfig} from '../config'
    
const Signout =  () => {
    const { oktaAuth, authState } = useOktaAuth();
    const cookies = new Cookies();
    const [signoutStatus, setSignoutStatus] = useState(true)
    const sourceUrl = decodeURIComponent(window.location.href);
    useEffect(async () => {
        try {
            let redirectUrl = ""
            if (sourceUrl.includes("fromURI=")) redirectUrl = sourceUrl.substring(sourceUrl.indexOf("fromURI=") + 8);
            cookies.remove ('SMSESSION', {path:'/', domain: envConfig.COOKIE_DOMAIN});
            cookies.remove ('SMIDENTITY', {path:'/', domain:  envConfig.COOKIE_DOMAIN});
            cookies.remove ('TARGETURI', {path:'/', domain:  envConfig.COOKIE_DOMAIN});
            cookies.remove ('PWD_RESET', {path:'/', domain:  envConfig.COOKIE_DOMAIN});
            var accessToken = await oktaAuth.tokenManager.get("accessToken");
            oktaAuth.signOut({
                postLogoutRedirectUri: decodeURIComponent(redirectUrl),
                accessToken: accessToken
             });
            await oktaAuth.revokeAccessToken(); // optional
            //close okta tenant session
            await oktaAuth.closeSession();
            setSignoutStatus('success');
        }
        catch (e) { 
            console.error(e);
            setSignoutStatus('failure');
        }
    }, [] );

  return (
        <div>
        {!signoutStatus && <div>Signing out...</div>}
        </div>
        
    );

}

export default Signout;
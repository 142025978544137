import {envConfig} from './config'

const REDIRECT_URI = `${window.location.origin}/login/callback`;


const oktaAuthConfig = {
   
    issuer: envConfig.OKTA_ISSUER,
    clientId: envConfig.OKTA_CLIENT_ID,
    redirectUri: REDIRECT_URI,
  };
  
  const oktaSignInConfig = {
    //logo: '/cardinal_logo_small.gif', 
    logo: '/logo.png', 
    language: 'en',
    i18n: {
        'en': {
            'needhelp' : 'Need help signing in?',
            'primaryauth.title': 'Sign In',             // Changes the sign in text
            'primaryauth.submit': 'Sign In',            // Changes the sign in button
            'primaryauth.username.value': 'Enter Username',
            'primaryauth.password.value': 'Enter Password',
            'password.forgot.email.or.username.placeholder': 'Username',
            'password.forgot.email.or.username.tooltip': 'Username',
            'account.unlock.email.or.username.placeholder': 'Username',
            'account.unlock.email.or.username.tooltip': 'Username',
            'forgotpassword': 'Forgot Password',
            'password.forgot.sendText': 'Reset via text message',
            'password.forgot.sendEmail': 'Reset via email',
            'password.forgot.call' : 'Reset via voice call',
            'password.forgot.question.title': 'Answer Forgotten Password Challenge',

            'mfa.challenge.enterCode.placeholder' : 'Passcode',
            'mfa.challenge.verify' : 'Verify passcode',
            'mfa.sent' : 'Resend passcode',
            'mfa.resendCode': 'Resend passcode',
            'password.reset' : 'Update Password',
            'password.complexity.requirements.header' :'Password requirements',
            'password.expired.submit' : 'Update Password',
            'password.complexity.length.description' : 'At least {0} characters',
            'password.complexity.lowercase.description' : 'A lowercase letter',
            'password.complexity.uppercase.description' : 'An uppercase letter',
            'password.complexity.number.description' : 'A number',
            'password.complexity.symbol.description' : 'A symbol',
            'password.complexity.no_username.description' : 'No parts of your username',
            'recovery.sms': 'text message',
            'recovery.call' : 'voice call',
            'recovery.smsOrCall': 'Text message and voice call',
            'recovery.mobile.hint' :'{0} can only be used if a phone number has been configured',
            'error.auth.lockedOut' : 'Your account has been locked because you have exceeded the maximum number of login attempts. Please try again in 110 minutes or reset your password to unlock your account.',
            'error.username.required' : 'Please enter username',
            'error.password.required' :'Please enter password',
            'errors.E0000004' :'Invalid username and/or password, please try again.',
            'errors.E0000069' : 'Your account is locked because of too many authentication attempts. Please retry later or use the Forgot Password link.'
        }
    },
    features: {
        rememberMe: true,                             // Setting to false will remove the checkbox to save username
        multiOptionalFactorEnroll: true,            // Allow users to enroll in multiple optional factors before finishing the authentication flow.
        selfServiceUnlock: true,                    // Disable unlock feature, require password reset
        smsRecovery: true,                          // Enable SMS-based account recovery
        callRecovery: true,                         // Enable voice call-based account recovery
        router: true,                                 // Leave this set to true for this demo
        showPasswordToggleOnSignInPage: true,  
        showPasswordToggleOnPasswordResetPage: true,             //password hide or visble
       // idpDiscovery: true,
    },
    helpLinks: {
      help: 'https://www.cardinalhealth.com/en/cmp/un/rp/login-help-resources.html',
      custom: [
        {
          text: 'Forgot Username',
          href: '/forgot-username'
        }
        // , 
        // {
        //   text: 'Reactivate Account',
        //   href: '/reactivate'
        // } 
      ]
    },
    baseUrl: envConfig.OKTA_BASE_URL,
    clientId: envConfig.OKTA_CLIENT_ID,
    redirectUri: REDIRECT_URI,
    authParams: {
          issuer: envConfig.OKTA_ISSUER,
          scopes: ['openid', 'profile','groups','phone', 'email','okta.users.manage'],
    },  
  /*  idpDiscovery: {
      requestContext: window.location.href
    } */
    };
  
  export { oktaAuthConfig, oktaSignInConfig };
  
import React, {useState} from 'react';
import { retrieveUsername, reactivateAccount } from '../resource/APIServices';
import classes from "../../src/styles/signin.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-phone-input-2/lib/style.css'
import { useHistory } from "react-router-dom";
//var createBrowserHistory = require("history").createBrowserHistory;

const Form = ({ props }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    //const history = createBrowserHistory({forceRefresh:true})
    const history = useHistory();

    const handleSubmitReactivate = async (e) => {
        e.preventDefault();
        if (document.getElementById('reactivate-account-username')?.value === '') {
            setAlertMessage('Please enter username');
        } else {
            setErrorMessage("");
            setAlertMessage("");
            const isSuccess = await reactivateAccount(document.getElementById('reactivate-account-username')?.value);
            if(isSuccess){
                history.push('/reactivate/emailed');
            } else {
                history.push('/generic-error');
            }
        }
    }

    const handleSubmitUsername = async (e) => {
        e.preventDefault();
        if (document.getElementById('forgot-account-username').value === '') {
            setAlertMessage('Please enter email address');
        } else {
            setErrorMessage("");
            setAlertMessage("");
            const isSuccess = await retrieveUsername(document.getElementById('forgot-account-username').value);
            if(isSuccess) {
                history.push({ pathname: '/username/emailed', search: document.getElementById('forgot-account-username').value , state: { detail: document.getElementById('forgot-account-username').value } });
            } else {
                history.push('/generic-error')
            }
        }
    }

    const handleBack = (e) => {
        e.preventDefault();
        history.push('/signin');
    }

    if (props.path === 'username_emailed') {
        var emailId = "";
        if (props && props.value && props.value.detail) emailId = props.value.detail;
        return (
            <div style={{ classes }} data-testid="username_emailed" className="forgot-username">
                <form onSubmit={handleBack} data-se="pwd-reset-email-sent" slot="content" id="form84" className="o-form o-form-edit-mode">
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <br></br>
                        <p id="custom-title-txt">Check your email.</p>
                        <p id="custom-title-info">Your username has been sent to {emailId}</p>
                        <p id="custom-title-info">If this email address matches our records, you should receive an email within a few minutes.</p>
                        <p id="custom-title-info">For further assistance, contact your sales representative.</p>
                        <hr id="custom-title-hr" />
                    </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <br></br>
                            <button data-se="back-button" className="button button-primary button-wide" href="#">Sign in</button>
                        </div>
                </form>
            </div>
        );
    }
    if (props === 'reactivate_emailed') {
        return (
            <div style={{ classes }} data-testid="reactivate_emailed" className="forgot-username">
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <br></br>
                        <p id="custom-title-txt">Check your email.</p>
                        <p id="custom-title-info">An Activation Link has been sent to you.</p>
                        <p id="custom-title-info">If your username matches our records, you should receive an email within a few minutes.</p>
                        <p id="custom-title-info">For further assistance, contact your sales representative.</p>
                        <hr id="custom-title-hr" />
                    </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <br></br>
                            <button data-se="back-button" onClick={handleBack} className="button button-primary button-wide" href="#">Sign in</button>
                        </div>
            </div>
        );
    }


    if (props === 'recovery_emailed') {
        return (
            <div style={{ classes }} data-testid="recovery_emailed" className='forgot-username'>
                <div>
                    <br></br>
                    <span id="custom-title-txt">Check your email.</span>
                    <p id="custom-title-info">A reset link has just been sent to you.</p>
                    <p id="custom-title-info">If a valid username was entered, you will receive the email within a few minutes.</p>
                    <p id="custom-title-info">Forgot your username? <a href="/forgot-username" className="link help js-back" data-se="back-link">Retrieve your username</a></p>
                    <hr id="custom-title-hr-thin" />
                </div>
                <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                    <br></br>
                    <button data-se="email-button" onClick={handleBack} className="button button-primary button-wide email-button" href="#" >Sign in</button>
                </div>
            </div>
        );
    }

    
    if (props === 'unlock_emailed') {
        return (
            <div style={{ classes }} data-testid="unlock_emailed" className='forgot-username'>
                <div>
                    <br></br>
                    <span id="custom-title-txt">Check your email.</span>
                    <p id="custom-title-info">A link to unlock your account has just been sent to you.</p>
                    <p id="custom-title-info">If your username matches our records, you should receive an email within a few minutes.</p>
                    <hr id="custom-title-hr-thin" />
                </div>
                <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                    <br></br>
                    <button data-se="email-button" onClick={handleBack} className="button button-primary button-wide email-button" href="#" >Back to Sign in</button>
                </div>
            </div>
        );
    }

    if (props === 'reactivate') {
       return (
            <div style={{ classes }} data-testid="reactivate" className='reactivate-account'>
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <h2 data-se="o-form-head" className="okta-form-title o-form-head">Reactivate Account</h2>
                        
                        <div>
                            <br></br>
                            <span id="custom-title-txt">Activation link request</span>
                            <p id="custom-title-info">Please enter the username that is associated with your account.</p>
                            <hr id="custom-title-hr" />
                        </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-label" className="okta-form-label o-form-label">
                                    <label id="reactivate-username">Username</label>
                                </div>
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                    <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                                        <input type="text" placeholder="Enter username" name="username" id="reactivate-account-username" aria-label="" autoComplete="username" aria-describedby="input-container-error38" aria-invalid="true" />
                                    </span>
                                    {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                                </div>
                            </div>
                            <br></br>
                            <div> <hr id="custom-title-hr-thin" /></div>
                            <br></br>
                            <button data-se="email-button" onClick={handleSubmitReactivate} className="button button-primary button-wide email-button" href="#">Submit</button>
                        </div>
                    </div>
                <div className="auth-footer" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in</a></div>
            </div>
        );
    }

    const emailValidator= (e) => {
        let enteredEmailAddr = "";
        if (e && e.target && e.target.value) enteredEmailAddr = e.target.value;
        var emailValidatorRegex = /\S+@\S+\.\S+/;
        let submitUsernameBtn =  document.getElementById('submitUsername');
        if (enteredEmailAddr && !emailValidatorRegex.test(enteredEmailAddr)) {
            submitUsernameBtn.disabled = true;
           // submitUsernameBtn.className ='button button-primary button-wide email-button link-button-disabled'
            setAlertMessage("Email address format is not correct");
        } else {
          submitUsernameBtn.disabled = false;
          //submitUsernameBtn.className ='button button-primary button-wide email-button link-button'
          setAlertMessage(undefined);
        }
    }


    if (props.path === 'username') {
        return (
            <div style={{ classes }} data-testid="username" className='forgot-username'>
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <h2 data-se="o-form-head" className="okta-form-title o-form-head">Forgot Username</h2>
                        {errorMessage && <div className="okta-form-infobox-error infobox infobox-error" role="alert">
                            <span className="icon error-16"></span><p name="error-message">{errorMessage}</p>
                        </div>}
                        <div>
                            <br></br>
                            <span id="forgot-password-title-txt">Forgot Username</span>
                            <p id="forgot-password-title-info">Please enter the email address that is associated with your account.</p>
                            <hr id="custom-title-hr-thin" />
                        </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top">
                                    <label id="forgot-username">Email address</label>
                                </div>
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                    <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                                        <input type="text" placeholder="Enter email address" name="username" id="forgot-account-username" onBlur={emailValidator} onFocus={emailValidator} />
                                    </span>
                                    {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                                </div>
                            </div>
                                   <hr id="custom-title-hr-thin" />
                            <br></br>
                            <button id="submitUsername" data-se="email-button" onClick={handleSubmitUsername} className="button button-primary button-wide email-button" href="#" >Retrieve username</button>
                        </div>
                    </div>
                <div className="auth-footer" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in</a></div>
            </div>
        );
    }

    if (props === 'generic_error') {
        return (
            <div style={{ classes }} >
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <br></br>
                        <p id="custom-title-txt">Sorry, an error occured.</p>
                        <p id="custom-title-info">We are working to fix the issue. Please try logging in again. <a className="link help js-back" href='/signout'>Sign in now</a>.</p>
                        <p id="custom-title-info">Or, if the problem persists, call 1-800-326-6457.</p>
                        <br></br>
                    </div>
            </div>
        );
    }
    return null;
}


export default Form;



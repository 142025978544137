import React, {  useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import classes from "../styles/signin.scss";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import EnrollmentForm from "./EnrollmentForm";
//import logo from "../images/logo-main.png";
import logo from "../images/logo.png";
import { useLocation } from "react-router-dom";


export const getEnrollmentType = (pathReceived) => {
  let enrollmentRoute = "";
  if (pathReceived.includes("/phone-enrollment"))
    enrollmentRoute = "phone-enrollment";
  else if (pathReceived.includes("/email-enrollment"))
    enrollmentRoute = "email-enrollment";
  else if (pathReceived.includes("/setup-phone-enrollment"))
    enrollmentRoute = "setup-phone-enrollment";
  else if (pathReceived.includes("/enrolled")){
    enrollmentRoute = "enrolled";
  }
    return enrollmentRoute;
}


const EnrollmentUI = () => {
  const { authState } = useOktaAuth();
  const location = useLocation();

  useEffect(() => {
  }, [location]);

  if (!authState) return null;

  return (
    <div >
      <div className='margin-center-widget'>
        <main className="auth-container main-container no-beacon" style={{ classes }} data-se="auth-container" tabndex="-1" id="okta-sign-in">
          <div className="okta-sign-in-header auth-header">
            <h1><img src={logo} className="auth-org-logo" alt=" logo" aria-label=" logo" /></h1>
            <div data-type="beacon-container" className="beacon-container"></div>
          </div>
          <div className="auth-content">
            <div className="auth-content-inner">
              <EnrollmentForm props={getEnrollmentType(window.location.pathname)} />
            </div>
          </div>
        </main>
      </div>
      <div id="footer-container-div" className="footer-container-relative">
        <div id="footer">
          <a className="legal-privacy" target="_blank"  href="https://www.cardinalhealth.com/us/en/Legal">&nbsp;&nbsp;Legal</a>
          &nbsp;|&nbsp;
          <a className="legal-privacy" target="_blank"  href="http://cardinalhealth.com/us/en/PrivacyPolicy/PrivacyPolicy">Privacy policy</a>
        </div>
        <div className="copyright">
          <p>© {new Date().getFullYear()} Cardinal Health, Inc. All rights reserved</p>
        </div>
       </div> 
    </div>
  );
};

export default EnrollmentUI;

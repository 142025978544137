/* istanbul ignore file */
import React, {useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "./../../src/styles/signin.scss";
import classes from "./../../src/styles/logo.scss";
import { accountUnlockViaEmail, passwordRecoveryViaEmail, accountUnlockConfirmationEmail } from "./../../src/resource/APIServices";
import { useHistory } from "react-router-dom";
import {envConfig, resetPwd }from "./../config";
import { useCookies } from 'react-cookie';
import Modal from "../CustomUIs/Modal";
//var createBrowserHistory = require("history").createBrowserHistory;


const OktaSignInWidget = ({ config, onSuccess, onError }) => {
  const [cookies, setCookie] = useCookies(['PWD_RESET']);
  const history = useHistory()
  const widgetRef = useRef();
  const sourceUrl = decodeURIComponent(window.location.href);

  var fromUri = "";
  if(sourceUrl.includes("TARGET=-SM-")){
    fromUri= sourceUrl.substring(sourceUrl.indexOf("TARGET=-SM-")+ 11);
  }else if(sourceUrl.includes("fromURI="))
    fromUri= sourceUrl.substring(sourceUrl.indexOf("fromURI=")+ 8);
 
  const params = new URL(sourceUrl).searchParams;
  const login_hint = params.get('login_hint');
  if (login_hint) { 
    config.username = login_hint; // Populates the SIW with the login  
    }

  var whereItCameFrom = "OERIAcute";
  if (fromUri.includes("specialtyonline")) {
    whereItCameFrom = "SPD";
  } else if (fromUri.includes("www1")) {
    whereItCameFrom = "RCNational"
  }

  console.log("Config Value from Ngnix" + envConfig.FIRST_VAR + ' whereItCameFrom ' + whereItCameFrom);


  useEffect(() => {
    if (!widgetRef.current) return false;
    const widget = new OktaSignIn(config);
    try {
      // after successful authentication with IdP
      widget.authClient.session.exists().then(function (sessionExists) {
        if (sessionExists) {
          console.log("session exists");
          widget.authClient.token
            .getWithoutPrompt()
            .then(function (response) {
              console.log("Session Response " + response.tokens);
              console.log("Session Response Obj " + JSON.stringify(response.tokens));
              widget.authClient.tokenManager.setTokens(response.tokens);
              onSuccess(response.tokens);
            }).catch(function (err) {
              console.log("session exists but error");
              console.error(err);
              // handle OAuthError or AuthSdkError (AuthSdkError will be thrown if app is in OAuthCallback state)
              widget.authClient
                .closeSession()
                .then(() => {
                  window.location.reload();
                })
                .catch(onError);
            });
        } else {
          console.log("session does not exist");
          widget.showSignInToGetTokens({el: widgetRef.current,}).then(onSuccess).catch(onError);
        }
      });
    } catch (err) {
      console.log("unknown error");
      console.error(err);
      window.location.reload();
    }

    widget.on("afterError", function (context, error) {
      if (
        context.controller !== "primary-auth" &&
        context.controller !== "forgot-password" &&
        context.controller !== "recovery-challenge" &&
        context.controller !== "password-reset" &&
        context.controller !== "recovery-loading" &&
        context.controller !== "password-expired"
      ) {
        return;
      }
       var infoBoxError = document.getElementsByClassName("okta-form-infobox-error infobox infobox-error");
      if (context.controller === "primary-auth") {
         if (infoBoxError[0] && infoBoxError[0].innerText.includes("The resource owner or authorization server denied the request")) {
          infoBoxError[0].innerHTML ='<span class="icon error-16"></span>Login fail:Your computer\'s IP address <br>or login credential is not authorized <br>to access Order Express.<br>If you need further assistance, contact our <br>service center at 1-800-326-6457';
        }
        if (infoBoxError[0] && infoBoxError[0].innerText.includes( "Your account has been locked because you have exceeded the maximum number of login attempts")        ) {
          infoBoxError[0].innerHTML = '<span class="icon error-16"></span>Your account has been locked because you have exceeded the maximum number of login attempts. <br> Please try again in 10 minutes or <a class="anchor-link" href=/signin/forgot-password>reset your password to unlock your account</a>';
        }
        if (infoBoxError[0] && infoBoxError[0].innerText.includes( "Invalid username and/or password, please try again.")        ) {
          infoBoxError[0].innerHTML = '<span class="icon error-16"></span>Invalid username and/or <br>password, please try again.';
        }
      }
      if (context.controller === "recovery-challenge") {
        if (infoBoxError[0] && infoBoxError[0].innerText.includes("Your token doesn't match our records. Please try again.")) {
          infoBoxError[0].innerHTML ='<span class="icon error-16"></span><p>Your passcode doesn\'t match our records. Please try again or resend passcode.</p>';        }
      }
      if (context.controller === "password-reset") {
        if (infoBoxError[0] && infoBoxError[0].innerText.includes("Password requirements were not met")) {
          infoBoxError[0].innerHTML ='<span class="icon error-16"></span><p>Password requirements were not met. Please review and try again.</p>';
        }
        if (infoBoxError[0] && infoBoxError[0].innerText.includes("You have accessed an account recovery link that has expired or been previously used")) {
          infoBoxError[0].innerHTML ='<span class="icon error-16"></span><p>This activation link expired and can only be used once. New Users <a class="anchor-link" href=/signin/reactivate>click here</a> to activate your user or <a class="anchor-link" href=/signin/forgot-password>click here</a> to reset your password.</p>';
        }
        if (infoBoxError[0] && infoBoxError[0].innerText.includes("The resource owner or authorization server denied the request")) {
          infoBoxError[0].innerHTML ='<span class="icon error-16"></span><p>Your computer\'s IP address or login credential is not authorized to access Order Express. <br>If you need further assistance, contact our service center at 1-800-ECOMHLP (800-326-6457)</p>';
        }
      }
      if (context.controller === "password-expired") {
        if (infoBoxError[0] && infoBoxError[0].innerText.includes("Old Password is not correct")) {
          infoBoxError[0].innerHTML ='<span class="icon error-16"></span><p>Invalid password: the password you\'ve entered does not match our record, please try again or <a class="anchor-link" href=/signin/forgot-password>reset your password here</a></p>';
        }
        if (infoBoxError[0] && infoBoxError[0].innerText.includes("The resource owner or authorization server denied the request")) {
          infoBoxError[0].innerHTML ='<span class="icon error-16"></span><p>Your computer\'s IP address or login credential is not authorized to access Order Express. <br>If you need further assistance, contact our service center at 1-800-ECOMHLP (800-326-6457)</p>';
        }
      }
      if (context.controller === "recovery-loading") {
          document.getElementById('widget-content').className = 'margin-center-widget';
        if (infoBoxError[0] && infoBoxError[0].innerText.includes("You have accessed an account recovery link that has expired or been previously used")) {
          infoBoxError[0].innerHTML = '<br> <div id="custom-title-txt">Your link has expired.</div><p>This activation link has expired and can only be used once. To activate your account, please request a new link.</p> <br>  <p> For new users: <a class="anchor-link" href=/reactivate>Reactivate your account</a></p> <br> <p> For existing users: <a class="anchor-link" href=/signin/forgot-password>Reset your password</a></p>';
          infoBoxError[0].className = ""
        }
      }
    });
    widget.on("afterRender", function (context) {
      if (
        context.controller !== "forgot-password" &&
        context.controller !== "recovery-challenge" &&
        context.controller !== "primary-auth" &&
        context.controller !== "forgot-username" &&
        context.controller !== "password-reset" &&
        context.controller !== "password-expired" &&
        context.controller !== "account-unlock" &&
        context.controller !== "account-unlocked"
      ) {
        return;
      }

       if (context.controller === "primary-auth") {
        if(document.getElementById("footer-container-div") !== null) {
          document.getElementById('footer-container-div').className = 'footer-container-relative';
        }
        if(document.getElementsByClassName('link js-help-link') !== null && document.getElementsByClassName('link js-help-link')[0] !== null) {
          document.getElementsByClassName('link js-help-link')[0].setAttribute('target', '_self');
        }
        if(whereItCameFrom === 'RCNational') {
          document.getElementsByClassName('link help js-help')[0].style.display = 'none';
        }
        document.getElementById('logo-content').style.display = 'block';
        document.getElementById('widget-content').className = 'margin-left-widget';
        document.getElementById('okta-sign-in').style.height = '570px';

        if (document.getElementById("passcode-title-div") !== null) {
          document.getElementById("passcode-title-div").remove();
        }
        if(document.getElementById("forgot-password-title-div") !== null)
          document.getElementById("forgot-password-title-div").remove();
        var username_field = document.getElementById("okta-signin-username");
        username_field.className =  'okta-sign-in-username';
        if (username_field) username_field.placeholder = "Enter username";
        var password_field = document.getElementById("okta-signin-password");
        if (password_field) password_field.placeholder = "Enter password";
        var helpLink = document.getElementsByClassName("js-help-link");
        if (helpLink && helpLink[0])
          helpLink[0].textContent = "Step by step login guide";

        // Adding SSO link to the auth-footer
        const authFooterContainer = document.querySelector('.auth-footer');
        const ssoLink = document.createElement("a");
        ssoLink.setAttribute('href', 'javascript:void(0);');
        ssoLink.className = 'link js-sso-link';
        ssoLink.innerHTML = `Single sign-on (SSO)`;
        authFooterContainer.appendChild(ssoLink);

        const ssoModal = document.querySelector('.sso-modal');
        const backgroundOverlay = document.querySelector('.background-overlay');

        ssoLink.addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
          ssoModal.style.display = ssoModal.style.display === 'block' ? 'none' : 'block';
          backgroundOverlay.style.display = backgroundOverlay.style.display === 'block' ? 'none' : 'block';
        });
        document.addEventListener("click", function (e) {
          const ssoModal = document.querySelector('.sso-modal');
          const backgroundOverlay = document.querySelector('.background-overlay');
          if (!ssoModal.contains(e.target)) {
            ssoModal.style.display = 'none';
            backgroundOverlay.style.display = 'none';
          }
        });
      }

      if (context.controller === "forgot-password") {
        document.getElementById('okta-sign-in').style.removeProperty('height');

        var infoBoxError = document.getElementsByClassName("o-form-error-container");
        if(infoBoxError.length > 0){
          infoBoxError[0].style = "display: none";
        }
        
        if(document.getElementById("footer-container-div") !== null) {
          document.getElementById('footer-container-div').className = 'footer-container-relative';
        }

        if (document.getElementById("passcode-title-div") !== null) {
          document.getElementById("passcode-title-div").remove();
        }
       
        document.getElementById('logo-content').style.display = 'none';
        document.getElementById('widget-content').className = 'margin-center-widget';
     
        var username_rec_field = document.getElementById("account-recovery-username" );
        username_rec_field.placeholder = "Enter username";
        var containerDiv =  document.getElementsByClassName("forgot-password");
        if (document.getElementById("forgot-password-title-div") === null || !document.getElementById("forgot-password-title-div")) {
          const fpTitleDiv = document.createElement("div");
          fpTitleDiv.id = "forgot-password-title-div";
          const breakLine = document.createElement("br");
          fpTitleDiv.appendChild(breakLine);
          const fpTitleSpan = document.createElement("span");
          fpTitleSpan.id = "custom-title-txt";
          const fpTitleText = document.createTextNode("Forgot your password? Let's get you right back in.");
          fpTitleSpan.appendChild(fpTitleText);
          fpTitleDiv.appendChild(fpTitleSpan);

          const fpTitlePara = document.createElement("p");
          fpTitlePara.id = "custom-title-info";
          const fpTitleInfo = document.createTextNode("Please enter the username associated with your account and choose a password reset method.");
          fpTitlePara.appendChild(fpTitleInfo);
          fpTitleDiv.appendChild(fpTitlePara);

          const fpTitleHrLine = document.createElement("hr");
          fpTitleHrLine.id = "custom-title-hr";
          fpTitleDiv.appendChild(fpTitleHrLine);

          containerDiv[0].parentNode.insertBefore(fpTitleDiv,containerDiv[0]);
        }

        var pDiv = document.getElementsByClassName("mobile-recovery-hint");
        if(pDiv.length > 0 ) {
          pDiv[0].innerText = pDiv[0].innerText.concat(".")
          const breakLine = document.createElement("br");
          const breakLine1 = document.createElement("br");
          pDiv[0].appendChild(breakLine);
          pDiv[0].appendChild(breakLine1);
        }
        if(document.getElementById("custom-title-hr2") == null){
          const fpTitleHrLine2 = document.createElement("hr");
          fpTitleHrLine2.id = "custom-title-hr2";
          pDiv[0].appendChild(fpTitleHrLine2);
        }

        var emailButton = document.getElementsByClassName("email-button")[0];
        var newEmailButton = emailButton.cloneNode(true);
        emailButton.parentNode.replaceChild(newEmailButton, emailButton);
        newEmailButton.addEventListener("click", async(e) =>{
          e.preventDefault();
          e.stopPropagation();
          //request API gateway for recovery token
          const user = document.getElementById("account-recovery-username").value;
          if (user === "") {
            emailButton.click();
            return;
          }
          const passwordRecoveryEmailResponse = await passwordRecoveryViaEmail(user);
          if(passwordRecoveryEmailResponse){
            history.push("/recovery/emailed");
          } else {
            console.error('Error in invoking PasswordRecoveryEmail API ' + JSON.stringify(passwordRecoveryEmailResponse))
            history.push("/generic-error");
          }

        });
      }

      if (context.controller === "password-reset") {
        document.getElementById('okta-sign-in').style.removeProperty('height');
        if(document.getElementById("footer-container-div") !== null) {
          document.getElementById('footer-container-div').className = 'footer-container-relative';
        }
        if (document.getElementById("passcode-title-div") !== null) {
          document.getElementById("passcode-title-div").remove();
        }
        if(document.getElementById("forgot-password-title-div") !== null)
          document.getElementById("forgot-password-title-div").remove();

        var subTitleDiv = document.getElementsByClassName("okta-form-subtitle o-form-explain");
        if(subTitleDiv.length > 0 ) {
          subTitleDiv[0].remove();
        }
        document.getElementById('logo-content').style.display = 'none';
        document.getElementById('widget-content').className = 'margin-center-widget';
        var inputTexts = document.getElementsByTagName("input");
        if (inputTexts && inputTexts[0].name.includes("newPassword")) { 
          inputTexts[0].placeholder = "Enter new password";
        }
        if (inputTexts && inputTexts[1].name.includes("confirmPassword")) {
          inputTexts[1].placeholder = "Enter new password";
        }

        var inputLabels = document.getElementsByTagName("label");
        if (inputLabels &&inputLabels[1].innerText.includes("Repeat password")) {
          inputLabels[1].innerText = "Confirm password";
        }

        if (document.getElementsByClassName("password-reset").length > 0 && document.getElementById("password-reset-title-div") === null) {
           var containerDiv = document.getElementsByClassName("okta-form-title o-form-head");
           var containerDiv1 = document.getElementsByClassName("o-form-fieldset-container");

          const passwordResetTitleDiv = document.createElement("div");
          passwordResetTitleDiv.id = "password-reset-title-div";
          const breakLinePR = document.createElement("br");
          passwordResetTitleDiv.appendChild(breakLinePR);
          const passwordResetTitleSpan = document.createElement("span");
          passwordResetTitleSpan.id = "custom-title-txt";
          const passwordResetTitleText = document.createTextNode("Password reset");
          passwordResetTitleSpan.appendChild(passwordResetTitleText);
          passwordResetTitleDiv.appendChild(passwordResetTitleSpan);

          const passwordResetTitlePara = document.createElement("p");
          passwordResetTitlePara.id = "custom-title-info";
          const passwordResetTitleInfo = document.createTextNode("Please complete the fields below to update your password.");
          passwordResetTitlePara.appendChild(passwordResetTitleInfo);
          passwordResetTitleDiv.appendChild(passwordResetTitlePara);


          const passwordResetParaDiv = document.createElement("div");
          passwordResetParaDiv.id = "password-reset-para-div";

          const passwordResetTitleHrLine = document.createElement("hr");
          passwordResetTitleHrLine.id = "custom-title-hr";
          passwordResetParaDiv.appendChild(passwordResetTitleHrLine);

          const firstLine = document.createElement("p");
          firstLine.id = "custom-para-p-medium";
          const firstLineValue = document.createTextNode("Password requirements");
          firstLine.appendChild(firstLineValue);
          passwordResetParaDiv.appendChild(firstLine);
         /* const breakLine = document.createElement("br");
          passwordResetParaDiv.appendChild(breakLine);*/
          const secondLine = document.createElement("p");
          secondLine.id = "custom-para-p";
          const secondLineValue = document.createTextNode("At least 8 characters");
          secondLine.appendChild(secondLineValue);
          passwordResetParaDiv.appendChild(secondLine);
          const secondLine_a = document.createElement("p");
          secondLine_a.id = "custom-para-p";
          const secondLineValue_a = document.createTextNode("A lowercase letter");
          secondLine_a.appendChild(secondLineValue_a);
          passwordResetParaDiv.appendChild(secondLine_a);
          const thirdLine = document.createElement("p");
          thirdLine.id = "custom-para-p";
          const thirdLineValue = document.createTextNode("An uppercase letter");
          thirdLine.appendChild(thirdLineValue);
          passwordResetParaDiv.appendChild(thirdLine);
          const thirdLine_a = document.createElement("p");
          thirdLine_a.id = "custom-para-p";
          const thirdLineValue_a = document.createTextNode("A numeral");
          thirdLine_a.appendChild(thirdLineValue_a);
          passwordResetParaDiv.appendChild(thirdLine_a)
          const fourthLine = document.createElement("p");
          fourthLine.id = "custom-para-p"
          const fourthLineValue = document.createTextNode("A special character\u00A0(Ex.\u00A0!@#$%&)");
          fourthLine.appendChild(fourthLineValue);
          passwordResetParaDiv.appendChild(fourthLine);
          const fifthLine = document.createElement("p");
          fifthLine.id = "custom-para-fifth-p"
          const fifthLineValue = document.createTextNode("Must be different than your previous 9 passwords");
          fifthLine.appendChild(fifthLineValue);
          passwordResetParaDiv.appendChild(fifthLine);
          /*const breakLine2 = document.createElement("br");
          passwordResetParaDiv.appendChild(breakLine2);*/

          const passwordResetButtonDiv = document.createElement("div");
          passwordResetButtonDiv.id = "password-reset-button-div";

          const passwordResetTitleHrLine2 = document.createElement("hr");
          passwordResetTitleHrLine2.id = "custom-title-hr";
          passwordResetButtonDiv.appendChild(passwordResetTitleHrLine2);

          containerDiv[0].parentNode.insertBefore(passwordResetTitleDiv,containerDiv[0]);
          containerDiv1[0].parentNode.insertBefore(passwordResetParaDiv,containerDiv1[0]);
          containerDiv1[0].parentNode.appendChild(passwordResetButtonDiv,containerDiv1[0]);


          if (document.getElementsByClassName("okta-form-subtitle o-form-explain").length > 0) {
            var passwordReqPara = document.getElementsByClassName("okta-form-subtitle o-form-explain" );
            if ( passwordReqPara.length > 0 && passwordReqPara[0].innerText.includes("Password requirements")) {
              passwordReqPara[0].remove();
            }
          }
        }
        var updatePasswordBtn = document.getElementsByClassName('button button-primary')[0]
        updatePasswordBtn.parentNode.insertBefore( document.createElement("br"),updatePasswordBtn);
        updatePasswordBtn.addEventListener("click", function (e) {
            resetPwd.hasTriggered = true;
            setCookie('PWD_RESET', 'YES' , { path: '/', domain: envConfig.COOKIE_DOMAIN, encode: v => v });
        });

      }

      if (context.controller === "password-expired") {
        document.getElementById('okta-sign-in').style.removeProperty('height');

        if(document.getElementById("footer-container-div") !== null) {
          document.getElementById('footer-container-div').className = 'footer-container-relative';
        }

        if (document.getElementById("passcode-title-div") !== null) {
          document.getElementById("passcode-title-div").remove();
        }
        if(document.getElementById("forgot-password-title-div") !== null)
          document.getElementById("forgot-password-title-div").remove();

        document.getElementById('logo-content').style.display = 'none';
        document.getElementById('widget-content').className = 'margin-center-widget';

        if(document.getElementsByClassName("okta-form-divider") != null) {
          document.getElementsByClassName("okta-form-divider")[0].style.display = 'none'
        }

        var inputTexts = document.getElementsByTagName("input");
        if (inputTexts && inputTexts[0].name.includes("oldPassword")) {
          inputTexts[0].placeholder = "Enter old password";
        }
        if (inputTexts && inputTexts[1].name.includes("newPassword")) {
          inputTexts[1].placeholder = "Enter new password";
        }
        if (inputTexts && inputTexts[2].name.includes("confirmPassword")) {
          inputTexts[2].placeholder = "Enter new password";
        }

        var inputLabels = document.getElementsByTagName("label");
        if (inputLabels && inputLabels[2].innerText.includes("Repeat password")) {
          inputLabels[2].innerText = "Confirm password";
        }

        if (document.getElementsByClassName("password-expired").length > 0 && document.getElementById("custom-title-div") === null) {
          var formTitle = document.getElementsByClassName('okta-form-title')[0].innerText;
          let passwordExpiryTitleMsg = "Your password is expired";
          let passwordExpiryTitleInfoMsg = "Please complete the fields below to update your password.";
          
          if(formTitle === "Your password will expire later today" || formTitle.includes('days')){
            passwordExpiryTitleMsg = formTitle
          } 

          if (!(passwordExpiryTitleMsg === "Your password is expired")) {
            passwordExpiryTitleInfoMsg = "To avoid getting locked out of your account, please update your password."
            const signoutLink = document.getElementsByClassName("js-signout");
            if(signoutLink.length > 0) signoutLink[0].style = "float: right"
           // document.getElementsByClassName('okta-form-subtitle').remove()
          }
          var containerDiv = document.getElementsByClassName("o-form-fieldset-container");
          const passwordExpiredTitleDiv = document.createElement("div");
          passwordExpiredTitleDiv.id = "custom-title-div";
          const breakLinePR = document.createElement("br");
          passwordExpiredTitleDiv.appendChild(breakLinePR);
          const passwordExpiredTitleSpan = document.createElement("span");
          passwordExpiredTitleSpan.id = "custom-title-txt";
          const passwordExpiredTitleText = document.createTextNode(passwordExpiryTitleMsg);
          passwordExpiredTitleSpan.appendChild(passwordExpiredTitleText);
          passwordExpiredTitleDiv.appendChild(passwordExpiredTitleSpan);

          const passwordExpiredTitlePara = document.createElement("p");
          passwordExpiredTitlePara.id = "custom-title-info";
          const passwordExpiredTitleInfo = document.createTextNode(passwordExpiryTitleInfoMsg);
          passwordExpiredTitlePara.appendChild(passwordExpiredTitleInfo);
          passwordExpiredTitleDiv.appendChild(passwordExpiredTitlePara);

          const passwordExpiredTitleHrLine = document.createElement("hr");
          passwordExpiredTitleHrLine.id = "custom-title-hr";
          passwordExpiredTitleDiv.appendChild(passwordExpiredTitleHrLine);

          const firstLine = document.createElement("p");
          firstLine.id = "custom-para-p-medium";
          const firstLineValue = document.createTextNode("Password requirements");
          firstLine.appendChild(firstLineValue);
          passwordExpiredTitleDiv.appendChild(firstLine);
          /*const breakLine = document.createElement("br");
          passwordExpiredTitleDiv.appendChild(breakLine);*/
          const secondLine = document.createElement("p");
          secondLine.id = "custom-para-p";
          const secondLineValue = document.createTextNode("At least 8 characters");
          secondLine.appendChild(secondLineValue);
          passwordExpiredTitleDiv.appendChild(secondLine);
          const secondLine_a = document.createElement("p");
          secondLine_a.id = "custom-para-p";
          const secondLineValue_a = document.createTextNode("A lowercase letter");
          secondLine_a.appendChild(secondLineValue_a);
          passwordExpiredTitleDiv.appendChild(secondLine_a);
          const thirdLine = document.createElement("p");
          thirdLine.id = "custom-para-p";
          const thirdLineValue = document.createTextNode("An uppercase letter");
          thirdLine.appendChild(thirdLineValue);
          passwordExpiredTitleDiv.appendChild(thirdLine);
          const thirdLine_a = document.createElement("p");
          thirdLine_a.id = "custom-para-p";
          const thirdLineValue_a = document.createTextNode("A numeral");
          thirdLine_a.appendChild(thirdLineValue_a);
          passwordExpiredTitleDiv.appendChild(thirdLine_a)
          const fourthLine = document.createElement("p");
          fourthLine.id = "custom-para-p";
          const fourthLineValue = document.createTextNode("A special character\u00A0(Ex.\u00A0!@#$%&)" );
          fourthLine.appendChild(fourthLineValue);
          passwordExpiredTitleDiv.appendChild(fourthLine);
          const fifthLine = document.createElement("p");
          fifthLine.id = "custom-para-fifth-p"
          const fifthLineValue = document.createTextNode("Must be different than your previous 9 passwords");
          fifthLine.appendChild(fifthLineValue);
          passwordExpiredTitleDiv.appendChild(fifthLine);
          /*const breakLine2 = document.createElement("br");
          passwordExpiredTitleDiv.appendChild(breakLine2);*/

          containerDiv[0].parentNode.insertBefore(passwordExpiredTitleDiv,containerDiv[0]);

          if (document.getElementsByClassName("okta-form-subtitle o-form-explain").length > 0) {
            var passwordReqPara = document.getElementsByClassName("okta-form-subtitle o-form-explain");
            if (passwordReqPara.length > 0 && (passwordReqPara[0].innerText.includes("Password requirements") || passwordReqPara[0].innerText.includes('When password expires'))) {
              passwordReqPara[0].remove();
            }
          }
        }
        const btnBar = document.getElementsByClassName('o-form-button-bar')
        const passwordExpiredButtonDiv = document.createElement("div");
        passwordExpiredButtonDiv.id = "custom-password-expired-btn-div";
        const breakLineButton = document.createElement("br");
        passwordExpiredButtonDiv.appendChild(breakLineButton);
        const passwordExpiredBtnHrLine = document.createElement("hr");
        passwordExpiredBtnHrLine.id = "custom-title-hr";
        passwordExpiredButtonDiv.appendChild(passwordExpiredBtnHrLine);
        passwordExpiredButtonDiv.appendChild(breakLineButton);

        var updatePasswordBtn = document.getElementsByClassName('button button-primary')[0]
        if(document.getElementById("custom-password-expired-btn-div") === null){
          updatePasswordBtn.parentNode.insertBefore(passwordExpiredButtonDiv, updatePasswordBtn)
        }
        updatePasswordBtn.addEventListener("click", function (e) {
            resetPwd.hasTriggered = true;
            setCookie('PWD_RESET', 'YES' , { path: '/', domain: envConfig.COOKIE_DOMAIN, encode: v => v });
        });

      }

      //Store the username entered on the screen where user selects the recovery method
      if (context.controller === "recovery-challenge") {
        document.getElementById('okta-sign-in').style.removeProperty('height');

        if(document.getElementById("footer-container-div") !== null) {
          document.getElementById('footer-container-div').className = 'footer-container-relative';
        }
        if (document.getElementById("passcode-title-div") !== null) {
          document.getElementById("passcode-title-div").remove();
          }
        if(document.getElementById("forgot-password-title-div") !== null)
          document.getElementById("forgot-password-title-div").remove();

        document.getElementById('logo-content').style.display = 'none';
        document.getElementById('widget-content').className = 'margin-center-widget';
        if(document.getElementsByClassName('link goto').length > 0) {
          document.getElementsByClassName('link goto')[0].classList.remove('goto')
        }
        
       
        var passCodeInputText = document.getElementsByTagName("input");
        if (passCodeInputText && passCodeInputText[0].name === "passCode") {
          passCodeInputText[0].placeholder = "Enter Passcode";
        }

        if (document.getElementsByClassName("recovery-challenge") &&
          document.getElementById("passcode-title-div") === null
        ) {
          //&& (document.getElementById('passcode-title-div') === null && !document.getElementById('passcode-title-div'))) {
          var containerDiv =  document.getElementsByClassName("recovery-challenge");
          const passCodeTitleDiv = document.createElement("div");
          passCodeTitleDiv.id = "passcode-title-div";
          const breakLine = document.createElement("br");
          passCodeTitleDiv.appendChild(breakLine);
          const passCodeTitleSpan = document.createElement("span");
          passCodeTitleSpan.id = "custom-title-txt";
          const passCodeTitleText = document.createTextNode("Enter your one-time passcode");
          passCodeTitleSpan.appendChild(passCodeTitleText);
          passCodeTitleDiv.appendChild(passCodeTitleSpan);

          const passCodeTitlePara = document.createElement("p");
          passCodeTitlePara.id = "custom-title-info";
          const passCodeTitleInfo = document.createTextNode("Please enter the one-time passcode that was \u2003\u2003\u2003sent to your device.");
          passCodeTitlePara.appendChild(passCodeTitleInfo);
          passCodeTitleDiv.appendChild(passCodeTitlePara);
/*
          const passCodeTitleHrLine = document.createElement("hr");
          passCodeTitleHrLine.id = "custom-title-hr";
          passCodeTitleDiv.appendChild(passCodeTitleHrLine);
*/
          containerDiv[0].parentNode.insertBefore( passCodeTitleDiv, containerDiv[0]);
        }

        const formContainer = document.getElementsByClassName("o-form-fieldset-container")
        if(formContainer.length > 0 && document.getElementById("custom-title-hr") === null) {
          const passCodeTitleHrLine = document.createElement("hr");
          passCodeTitleHrLine.id = "custom-title-hr";
          formContainer[0].parentNode.insertBefore(passCodeTitleHrLine, formContainer[0])
        }

        const passCodeTitleDiv1 = document.createElement("div");
        passCodeTitleDiv1.id = "passcode-title-custom-div";
        const passCodeTitlePara1 = document.createElement("p");
        passCodeTitlePara1.id = "custom-title-info-left";
        passCodeTitlePara1.className = "mobile-recovery-hint";
        const passCodeTitleInfo1 = document.createTextNode("If a valid username was entered, you will receive the one-time passcode within a few minutes.");
        const passCodeTitleSpan = document.createElement("span");

        
        const passCodeTitleDiv2 = document.createElement("div");
        passCodeTitleDiv2.id = "passcode-title-div-pa";

        const forgotUsernameLink = document.createElement("a");
        forgotUsernameLink.id = 'forgot-username-retrieve-link'
        forgotUsernameLink.href = '/forgot-username';
        forgotUsernameLink.innerHTML = "Retrieve your username";
        

        const passCodeTitlePara11 = document.createElement("p");
        passCodeTitlePara11.id = "custom-title-info-left";
        passCodeTitlePara11.className="mobile-recovery-hint";
        passCodeTitlePara11.innerHTML = 'Forgot your username? '
        passCodeTitlePara11.appendChild(forgotUsernameLink)
        passCodeTitlePara1.appendChild(passCodeTitleInfo1);
        passCodeTitleDiv1.appendChild(passCodeTitlePara1);
        passCodeTitleDiv2.appendChild(passCodeTitlePara11);
        const passCodeHrDiv = document.createElement("div");
        passCodeHrDiv.id = "passcode-title-hr-div";
        const passCodeTitleHrLine2 = document.createElement("hr");
        passCodeTitleHrLine2.id = "custom-title-hr2";
        passCodeHrDiv.appendChild(passCodeTitleHrLine2);
        passCodeHrDiv.appendChild(document.createElement("br"));
        var resendPasscodeButton = document.getElementsByClassName("button sms-request-button margin-top-30 link-button");
        var verifyPassCodeDiv = document.getElementsByClassName("o-form-button-bar");
        if(document.getElementById('custom-title-hr2') === null) {
           verifyPassCodeDiv[0].children[0].parentNode.insertBefore(passCodeHrDiv, verifyPassCodeDiv[0].children[0]); 
           verifyPassCodeDiv[0].children[0].parentNode.insertBefore(passCodeTitleDiv2, verifyPassCodeDiv[0].children[0]); 
           verifyPassCodeDiv[0].children[0].parentNode.insertBefore(passCodeTitleDiv1, verifyPassCodeDiv[0].children[0]); 
        }
        verifyPassCodeDiv[0].appendChild(resendPasscodeButton[0]);
        
        //document.getElementById('custom-title-hr2').appendChild(document.createElement("br"))
        //overwrite the "send via email" soft link on the SMS or voice call recovery pages
        var emailLink = document.getElementsByClassName("send-email-link")[0];
        let softLinkResetViaEmail = "/signin/forgot-password"
        if(emailLink.innerText.includes('Unlock via email')) {
          softLinkResetViaEmail = "/signin/unlock"
        }
        emailLink.addEventListener("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
          history.push(softLinkResetViaEmail);
        });
      }
    
    if(context.controller === 'account-unlock') {
      document.getElementById('okta-sign-in').style.removeProperty('height');

      var infoBoxError = document.getElementsByClassName("o-form-error-container");
      if(infoBoxError.length > 0){
        infoBoxError[0].style = "display: none";
      }
      if(document.getElementById("footer-container-div") !== null) {
        document.getElementById('footer-container-div').className = 'footer-container-relative';
      }
      document.getElementById('logo-content').style.display = 'none';
      document.getElementById('widget-content').className = 'margin-center-widget';
      var username_rec_field = document.getElementById("account-recovery-username");
      username_rec_field.placeholder = "Enter username";
      var username_label_field = document.getElementsByClassName("o-form-label")[0];
      if (document.getElementById("account-unlock-title-div") === null || !document.getElementById("account-unlock-title-div")) {
        const acTitleDiv = document.createElement("div");
        acTitleDiv.id = "account-unlock-title-div";
        const breakLine = document.createElement("br");
        acTitleDiv.appendChild(breakLine);
        const acTitleSpan = document.createElement("span");
        acTitleSpan.id = "custom-title-txt";
        const acTitleText = document.createTextNode("Locked Account? Let's get you right back in.");
        acTitleSpan.appendChild(acTitleText);
        acTitleDiv.appendChild(acTitleSpan);

        const acTitlePara = document.createElement("p");
        acTitlePara.id = "custom-title-info";
        const acTitleInfo = document.createTextNode("Please enter the username associated with your account.");
        acTitlePara.appendChild(acTitleInfo);
        acTitleDiv.appendChild(acTitlePara);

        const acTitleHrLine = document.createElement("hr");
        acTitleHrLine.id = "custom-title-hr";
        acTitleDiv.appendChild(acTitleHrLine);

        username_label_field.parentNode.insertBefore(acTitleDiv, username_label_field);
        var mobile_para = document.getElementsByClassName('mobile-recovery-hint')
        if(mobile_para.length > 0 ) {
          mobile_para[0].innerText = mobile_para[0].innerText.concat(".")
        }
        const acTitleHrLine2 = document.createElement("hr");
        acTitleHrLine2.id = "custom-title-hr2";
        mobile_para[0].appendChild(acTitleHrLine2);

        var unlockTextButton = document.getElementsByClassName('sms-button')[0];
        if (unlockTextButton && unlockTextButton.innerText === 'Send SMS') {
          unlockTextButton.innerText = 'Unlock via text message'
        }
        var unlockCallButton = document.getElementsByClassName('call-button')[0];
        if (unlockCallButton && unlockCallButton.innerText === 'Voice Call') {
          unlockCallButton.innerText = 'Unlock via voice call';
        }
        var unlockEmailButton = document.getElementsByClassName('email-button')[0];
        if (unlockEmailButton && unlockEmailButton.innerText === 'Send Email') {
          unlockEmailButton.innerText = 'Unlock via email'
        }
      }
      var emailButton = document.getElementsByClassName("email-button")[0];
      var newEmailButton = emailButton.cloneNode(true);
      emailButton.parentNode.replaceChild(newEmailButton, emailButton);
      newEmailButton.addEventListener("click", async (e) => {
        e.preventDefault();
        e.stopPropagation();
        //request API gateway for recovery token
        const user = document.getElementById("account-recovery-username").value;
        if (user === "") {
          emailButton.click();
          return;
        }
        const isSuccess = await accountUnlockViaEmail(user);
        if (isSuccess) {
          history.push("/unlock/emailed");
        } else {
          history.push("/generic-error");
        }
      });
    }
    if(context.controller === 'account-unlocked') {
      document.getElementById('okta-sign-in').style.removeProperty('height');

      if(document.getElementById("footer-container-div") !== null) {
        document.getElementById('footer-container-div').className = 'footer-container';
      }
      const userDetails = widget.getUser();
      if(userDetails.profile.login !== "") {
        console.log('Sending Account Unlock Success Email for user id' + userDetails.profile.login)
        accountUnlockConfirmationEmail(userDetails.profile.login)
      }
      document.getElementById('logo-content').style.display = 'none';
      document.getElementById('widget-content').className = 'margin-center-widget';
      if (document.getElementById("passcode-title-div") !== null) {
        document.getElementById("passcode-title-div").remove();
      }
      if (document.getElementById("forgot-password-title-div") !== null)
        document.getElementById("forgot-password-title-div").remove();

      if (document.getElementById("account-unlocked-title-div") === null) {
        const acULTitleDiv = document.createElement("div");
        acULTitleDiv.id = "account-unlocked-title-div";
        const acULTitleSpan = document.createElement("span");
        acULTitleSpan.id = "custom-title-txt";
        const acULTitleText = document.createTextNode("Success! Your account has been unlocked.");
        const breakLine = document.createElement("br");
        acULTitleSpan.appendChild(breakLine);
        acULTitleSpan.appendChild(acULTitleText);
        acULTitleDiv.appendChild(acULTitleSpan);
        var para = document.getElementsByClassName('okta-form-subtitle');
        if (para[0] && para[0].innerText.includes('You can log in using your existing username and password')) {
          para[0].innerHTML = '<span><p>Use your existing username and password to sign in. <a class="anchor-link" href=/signin>Sign in now </a> </p> <br> <p>If you\'ve forgotten your password,<a class="anchor-link" href=/signin/forgot-password>reset your password</a></p></span>';
        }
        para[0].parentNode.insertBefore(acULTitleDiv, para[0]);
      }
      document.getElementsByClassName('button button-primary button-wide link-button')[0].style.display = 'none';
    }
    });
    return () => widget.remove();


  }, [config, onSuccess, onError]);

  return (
    <div id= "main-page-div">
      <div id="logo-content">
        {(whereItCameFrom === "OERIAcute" || whereItCameFrom === "RCNational") && (<div id="OE-logo" style={{ classes }} />        )}
        {whereItCameFrom === "SPD" && <div id="SPD-logo" style={{ classes }} />}
      
      </div>
      <div id="widget-content" ref={widgetRef} />
      <Modal type="sso-modal" />
      <div className="background-overlay"></div>
    <div id="footer-container-div" className="footer-container">
        <div id="footer">
          <a className="legal-privacy" target="_blank"  href="https://www.cardinalhealth.com/us/en/Legal">&nbsp;&nbsp;Legal</a>
          &nbsp;|&nbsp;
          <a className="legal-privacy" target="_blank"  href="http://cardinalhealth.com/us/en/PrivacyPolicy/PrivacyPolicy">Privacy policy</a>
        </div>
        <div className="copyright">
          <p>© {new Date().getFullYear()} Cardinal Health, Inc. All rights reserved</p>
        </div>
       </div> 
    </div>
  );
};

export default OktaSignInWidget;

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppWithRouterAccess from './AppWithRouterAccess';
import { CookiesProvider } from "react-cookie";


const App = () => (
  <CookiesProvider>
  <Router forceRefresh>
    <AppWithRouterAccess/>
  </Router>
  </CookiesProvider>
);


export default App;

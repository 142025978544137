import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect,useLocation , useHistory} from "react-router-dom";
import {
  coexistenceRequest,
  resetPasswordConfirmationEmail,
  incrementUIBypassCounter,
  getFactorsEnrolled,
  getSessionsResponse} from "./resource/APIServices";
import { envConfig, resetPwd } from "./config";
import { useCookies } from 'react-cookie';
import Signout from "./json/signout";
//var createBrowserHistory = require("history").createBrowserHistory;

const DOMAIN = process.env.DOMAIN || "cardinalhealth.com";
let emailEnrolled = false;
let smsEnrolled = false;
let callEnrolled = false;
let userEnrollmentException = false;
let skippedEnrollment = false;

export const findTargetUrl = (userGroups, incomingTargetUri) => {
 console.log(userGroups.length  + ' '+  userGroups + ' -- ' + incomingTargetUri)
  // first check if incomingTargetUri is matching with one the userGroups
let userGrpTargetUrlMap = new Map();
userGrpTargetUrlMap.set(envConfig.ACUTE_GRP, [envConfig.ACUTE_TARGET_URL, envConfig.ACUTE_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.RI_GRP, [envConfig.RI_TARGET_URL, envConfig.RI_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.SPOL_GRP, [envConfig.SPOL_TARGET_URL, envConfig.SPOL_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.RC_GRP, [envConfig.RC_TARGET_URL, envConfig.RC_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.WEB_ORD_GRP, [envConfig.ACUTE_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.REPORT_GRP, [envConfig.REPORT_TARGET_URL, envConfig.REPORT_CENTER_URL])
userGrpTargetUrlMap.set(envConfig.INTFCADM_GRP, [envConfig.INTFCADM_TARGET_URL])

let hasMatchedWithGroup = false;
let hasPrimaryStorefrontGroup = false;
let hasSupplementAppGroup = false;
let supplementAppGroup = ''
let primaryStorefrontGroup = '';

userGroups.forEach(eachGrp => {
 if(!hasMatchedWithGroup && incomingTargetUri && [... userGrpTargetUrlMap.keys()].includes(eachGrp)){
      hasMatchedWithGroup = userGrpTargetUrlMap.get(eachGrp).some(targetUrl => incomingTargetUri.includes(targetUrl)); 
      console.log('hasMatchedWithGroup for ' + eachGrp + ' ' + hasMatchedWithGroup)
  }
  if([envConfig.ACUTE_GRP, envConfig.RI_GRP, envConfig.RC_GRP, envConfig.SPOL_GRP].includes(eachGrp)){
    hasPrimaryStorefrontGroup = true;
    primaryStorefrontGroup = eachGrp;
  }
  if([envConfig.WEB_ORD_GRP, envConfig.REPORT_GRP, envConfig.INTFCADM_GRP].includes(eachGrp)){
    hasSupplementAppGroup = true;
    supplementAppGroup = eachGrp;
  }
});

console.log("HasMatched? " + hasMatchedWithGroup + " hasPrimarySF" + hasPrimaryStorefrontGroup + " " + primaryStorefrontGroup + "hasSupplement " + hasSupplementAppGroup + " " + supplementAppGroup)


if(hasMatchedWithGroup){
  return incomingTargetUri;
} else if (!hasMatchedWithGroup && hasPrimaryStorefrontGroup) {
  return userGrpTargetUrlMap.get(primaryStorefrontGroup)[0];
} else if(!hasMatchedWithGroup && hasSupplementAppGroup) {
  return userGrpTargetUrlMap.get(supplementAppGroup)[0];
} else if (!hasMatchedWithGroup && !hasPrimaryStorefrontGroup && !hasSupplementAppGroup){
  return "Unavailable";
}
}

const Home = () => {
 // const history = createBrowserHistory({forceRefresh:true})
 const history = useHistory();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(null);
  const windowHrefUrl = decodeURIComponent(window.location.href);

  const { oktaAuth, authState } = useOktaAuth();
  
  const [coexistence, setCoexistence] = useState(null);
  const [cookies, setCookie] = useCookies(['SMSESSION']);

  useEffect(async () => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
      setCoexistence(null);
    } else {
      let getSessionsResponseTest = await getSessionsResponse();
      if (getSessionsResponseTest == false) {
        history.push("/signout");
      } else {
        try {
          var accessTokenObj = await oktaAuth.tokenManager.get("accessToken");
          let oktaUserInfoObj = await oktaAuth.getUser();
  
          if (resetPwd.hasTriggered || cookies.PWD_RESET == "YES") {
            resetPwd.hasTriggered = false;
            setCookie("PWD_RESET", "NO", {
              path: "/",
              domain: envConfig.COOKIE_DOMAIN,
              encode: (v) => v,
            });
            const resetPwdConfEmailResponse =
              await resetPasswordConfirmationEmail(oktaUserInfoObj);
          } else {
            console.log(
              "Reset Pwd Object not updated to true/No PWD_RESET cookie found"
            );
          }
          //Coexistence
          /* var user = accessTokenObj.claims.sub;
          if (user.includes("@" + DOMAIN)) {
            user = user.split("@")[0];
          } */
          const factors = await getFactorsEnrolled();
          if (factors && factors.data) {
            for (const factor of factors.data) {
              if (factor.factorType === "call" && factor.status === "ACTIVE") {
                callEnrolled = true;
              }
              if (factor.factorType === "sms" && factor.status === "ACTIVE") {
                smsEnrolled = true;
              }
              if (factor.factorType === "email" && factor.status === "ACTIVE") {
                emailEnrolled = true;
              }
            }
          }
          let coexistResponse;
          userEnrollmentException = oktaUserInfoObj.groups.includes(
            envConfig.EXCEPTION_GRP
          );
          if (location.search.includes("skipEnrollment")) {
            skippedEnrollment = true;
            console.log("Redirect happened after SkipEnrollment");
          } else {
            console.log("Redirect happened NOT SkipEnrollment");
          }
          console.log(
            `EmailEnrolled ${emailEnrolled}, CallEnrolled ${callEnrolled} , SmsEnrolled ${smsEnrolled} and UserEnrollmentExceptionGroup ${userEnrollmentException}`
          );
          if (
            emailEnrolled &&
            (callEnrolled || smsEnrolled) &&
            oktaUserInfoObj.UIBypassCounter !== "0"
          ) {
            const resetUIBypassCounterResponse = await incrementUIBypassCounter(
              "0"
            );
          }
  
          if (
            skippedEnrollment ||
            userEnrollmentException ||
            (emailEnrolled && (callEnrolled || smsEnrolled))
          ) {
            console.log(
              "Calling Coexistence, if its SkipEnrollmentClicked Or userEnrollException Or (EmailEnrolled, one of Call or SMS enrolled)"
            );
            coexistResponse = await coexistenceRequest(
              oktaUserInfoObj.userid,
              accessTokenObj.accessToken
            );
            if (
              coexistResponse &&
              coexistResponse.data &&
              coexistResponse.data.resultCode === "LOGIN_SUCCESS"
            ) {
              setCookie("SMSESSION", coexistResponse.data.sessionToken, {
                path: "/",
                domain: envConfig.COOKIE_DOMAIN,
                encode: (v) => v,
              });
              setCoexistence("success");
            } else {
              setCoexistence("failure");
              //setCoexistence("success"); // local testing..
            }
          }
          setUserInfo(oktaUserInfoObj);
        } catch (e) {
          setCoexistence("failure");
          console.error(e);
        }
      }
    }
  }, [authState, oktaAuth, location]); // Update if authState changes
  

  const VerifyCheck = async () => {
    // Email Not verified and Email Enrollment also not done, so navigate to email enrollment
     if(skippedEnrollment || userEnrollmentException || (emailEnrolled && (callEnrolled || smsEnrolled))) {
       routeToOELandingPage();
     } else if(!emailEnrolled) {
       history.push('/email-enrollment');
     } else if (emailEnrolled && (!callEnrolled  && !smsEnrolled)) {
       history.push('/phone-enrollment');
     }
     return <div></div>;
   };

   
  
  const routeToOELandingPage = () => {
    let incomingTargetUri = windowHrefUrl.includes("fromURI=") ? windowHrefUrl.substring(windowHrefUrl.indexOf("fromURI=") + 8) : cookies.TARGETURI;
    let target =null;
    
      if((incomingTargetUri.includes("jarvis")) || (incomingTargetUri.includes("bridgelogindev")) || (incomingTargetUri.includes("dashboard-orderexpress"))){
        target = incomingTargetUri;
      } else {
        target = findTargetUrl(userInfo.groups, incomingTargetUri);
      }
    
    console.log('Final Target for Redirection= ' + target)
    if(target === 'Unavailable') {
      history.push('/signout')
    } else {
      window.location.assign(target);
    }
  }

  if (!authState) return null;
  return authState.isAuthenticated ? (
    <div>
      {/*coexistence === "failure" && <Redirect to={{ pathname: "/signout" }} /> */}
      { coexistence === "failure" && <Signout/>}
      {(!userInfo || !coexistence) && <div></div>}
      {userInfo && <VerifyCheck/>}
    </div>
  ) : (<Redirect to={{ pathname: "/signin" }} />);
};

export default Home;
import React, {useEffect} from 'react';
import { enrollEmailWithoutOTP, getFactorsEnrolled, getUserInfoDetails, 
        incrementUIBypassCounter, verifyAndActivateFactorAPI,
        resendCode, sendPasscodeViaSMSOrCall, updateEmailInProfile, updatePhoneInProfile} from '../resource/APIServices';
import PhoneInput from '../../src/json/PhoneInput'
import classes from "../../src/styles/signin.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'react-phone-input-2/lib/style.css'
import {globalFactorId, envConfig} from '../config';
import Phone from '../../src/json/phone'
import { useLocation, useHistory } from "react-router-dom";

const EnrollmentForm = ({ props }) => {
    console.log('EnrollmentForm Props Received--' + props)
    const location = useLocation();
    console.log('EnrollmentForm location Received--' + JSON.stringify(location))

    const [errorMessage, setErrorMessage] = React.useState("");
    const [alertMessage, setAlertMessage] = React.useState("");
    const [passCodeAlertMessage, setPassCodeAlertMessage] = React.useState("");
    const [extensionAlertMessage, setExtensionAlertMessage] = React.useState("");
    const [phoneNumberAlertMessage, setPhoneNumberAlertMessage] = React.useState("");

  
    const [userInfo, setUserInfo] = React.useState(null);
    const history = useHistory();

   useEffect(() => {
     async function fetchUserInfoAndFactors() {
       let info = await getUserInfoDetails();
       setUserInfo(info);
     }
     fetchUserInfoAndFactors();
   }, [location]);

    const handleSubmitEmailWithoutOTP = async (e) => {
      const emailEntered = document.getElementById("enrollment-emailId").value;
      e.preventDefault();
      if (emailEntered === "") {
        setAlertMessage("Please enter email address");
      } else {
        setErrorMessage("");
        setAlertMessage("");
        if(userInfo && userInfo.data && userInfo.data.email) {
            if (userInfo.data.email !== emailEntered ) {
                console.log('Updating Email in Profile since User trying to change the email to enroll than whats in profile')
                const emailUpdateInProfileResponse = await updateEmailInProfile(emailEntered);
                console.log('Email Update in Profile Response ' +JSON.stringify(emailUpdateInProfileResponse))
            } else {
                console.log('User enrolling the same email id which is in profile');
            }
        }
        let enrollEmailResponse = await enrollEmailWithoutOTP(emailEntered);
        if (enrollEmailResponse.status === 200) {
          history.push({
            pathname: "/redirect"
          });
        } else {
          console.log("Enroll Email API Error" + JSON.stringify(enrollEmailResponse));
          history.push("/generic-error");
        }
      }
    };

    const verifyAndActivateFactorAPICall = selectedFactorType => async(e) => {
        e.preventDefault();
        if (document.getElementById('inputPasscode').value === '') {
            setPassCodeAlertMessage('This field cannot be left blank');
        } else {
            setAlertMessage("");
            let passcode = document.getElementById("inputPasscode").value
            let factorEnrollmentId;
            if (globalFactorId.enrollmentId && globalFactorId.enrollmentId !== null) {
                factorEnrollmentId = globalFactorId.enrollmentId
            } else {
                let factorDetails = await getFactorsEnrolled()
                if (factorDetails && factorDetails.data) {
                    for (let eachFactor of factorDetails.data) {
                        if (eachFactor.factorType === selectedFactorType && eachFactor.status === 'PENDING_ACTIVATION') {
                            factorEnrollmentId = eachFactor.id;
                        }
                    }
                }
            }
            const activateFactorResposne = await verifyAndActivateFactorAPI(passcode, factorEnrollmentId)
            
            if (activateFactorResposne && activateFactorResposne.data && activateFactorResposne.data.status === "ACTIVE") {
                let dialcode = document.getElementById('dialCode').value
                const phoneNumberElement = document.getElementsByName("phoneNumber");
                let phonenumber = phoneNumberElement[0].value
                let formattedNumber = dialcode + phonenumber;
                formattedNumber = formattedNumber.replace(/[^\d]/g,"");
                if(selectedFactorType === 'call' && document.getElementById('extensionNumber') && document.getElementById('extensionNumber').value.length > 0) {
                    formattedNumber =  formattedNumber + 'x' + document.getElementById('extensionNumber').value
                }
                const updatePhoneInProfileRes = await updatePhoneInProfile(formattedNumber, selectedFactorType)
                console.log('Response Update Phone ' + JSON.stringify(updatePhoneInProfileRes))
                history.push({ pathname: "/enrolled", search: selectedFactorType , state: { factorEnrolled: selectedFactorType } });
            } else {
                console.log('Activate Factor Enrollment not Active ' + JSON.stringify(activateFactorResposne))
                setPassCodeAlertMessage('Invalid Passcode.Please try again')
            }
        }
    }

    const clearPasscodeAlert = (e) => {
        setPassCodeAlertMessage("")
        setPhoneNumberAlertMessage("")
    }
  
    const handleBack = (e) => {
        e.preventDefault();
        history.push('/signout');
    }

   const handleBackSkipEnrollment = async (e) => {
       e.preventDefault();
       var userInfo = await getUserInfoDetails();
       if(userInfo && userInfo.data && userInfo.data.UIBypassCounter){
        var counter = parseInt(userInfo.data.UIBypassCounter);
        counter++;
        const isSuccess = await incrementUIBypassCounter(counter.toString());
       }
       history.push({ pathname: "/redirect", search: "skipEnrollment" , state: { skipEnrollment: true } });
    }
 
    const sendPasscodeAPICall = factorType => async (e) => {
        e.preventDefault();
        const phoneNumberElement = document.getElementsByName("phoneNumber");
        if(phoneNumberElement.length > 0 && phoneNumberElement[0].value.replace(/[^\d\+]/g, "").length === 10 ) {
            setPhoneNumberAlertMessage("")
            document.getElementById('passcodeInputbox').className = "show"
            document.getElementById('passcodebutton').className = "hidesendpasscode"
            document.getElementById('passcodehideshow').className = "hidesendpasscode"
            document.getElementById('brDiv').style.display = 'block';
            
            if(document.getElementsByName('phoneNumber').length > 0) document.getElementsByName('phoneNumber')[0].setAttribute('readonly','readonly') //disable phonenumber
            if(document.getElementsByName('extensionNumber').length > 0) document.getElementsByName('extensionNumber')[0].setAttribute('readonly','readonly') //disable extensionNumber
            if(document.getElementsByClassName('selected-flag').length > 0) document.getElementsByClassName('selected-flag')[0].style.display = 'none'; //disable country select
    
            const resendPasscodeBtn = document.getElementById("resendPasscode")
            resendPasscodeBtn.disabled = true;
            setTimeout(() => {
                if(globalFactorId.enrollmentId !== null) {
                    resendPasscodeBtn.disabled = false;
                    document.getElementById("resendPasscode").className = "button"
                } else {
                    console.error('Didnt get Enrollment Factor Id, so not enabling Resend Passcode Button')
                }
            }, 30000)
            let dialcode = document.getElementById('dialCode').value
            let phonenumber = phoneNumberElement[0].value
            let formattedNumber = dialcode + phonenumber
            let extensionNumber = ''
            if (document.getElementById('extensionNumber')) {
                extensionNumber = document.getElementById('extensionNumber').value
            }
            const sendPasscodeResponse = await sendPasscodeViaSMSOrCall(formattedNumber.replace(/[^\d\+]/g,""), extensionNumber, factorType);
            console.log('Send Passcode API Response ' + JSON.stringify(sendPasscodeResponse))
            if(sendPasscodeResponse && sendPasscodeResponse.data && sendPasscodeResponse.data.id) {
                globalFactorId.enrollmentId = sendPasscodeResponse.data.id
                console.log('Updated Factor Id for Factor type ' + factorType + ' ' + JSON.stringify(globalFactorId))
           }
        }  else {
            setPhoneNumberAlertMessage("Please enter valid phone number.")
        }
        
    }

    const resendPassCode = factorType => async (e) => {
        e.preventDefault();
        const resendPasscodeBtn = document.getElementById("resendPasscode")
        resendPasscodeBtn.disabled = true;
        resendPasscodeBtn.setAttribute('class', 'button button-primary button-wide email-button link-button-disabled')
        setTimeout(() => {
                resendPasscodeBtn.disabled = false;
                resendPasscodeBtn.setAttribute('class', 'button')
        }, 30000)
        let dialcode = document.getElementById('dialCode').value
        const phoneNumberElement = document.getElementsByName("phoneNumber");
        let phonenumber = phoneNumberElement[0].value
        let formattedNumber = dialcode + phonenumber
        const resendPasscodeResponse = await resendCode(formattedNumber.replace(/[^\d\+]/g,""), globalFactorId.enrollmentId, factorType);
        console.log("Resend Passcode API Resposne ", resendPasscodeResponse)
    }

    const orderExpress = async (e) => {
        e.preventDefault();
        history.push('/redirect')
    }

    const emailValidator= (e) => {
       let enteredEmailAddr = "";
      if (e && e.target && e.target.value) enteredEmailAddr = e.target.value;
      var emailValidatorRegex = /\S+@\S+\.\S+/;
      let submitEmailBtn =  document.getElementById('submitEmail');
      if (enteredEmailAddr && !emailValidatorRegex.test(enteredEmailAddr)) {
        submitEmailBtn.disabled = true;
        setAlertMessage("Email address format is not correct");
      } else {
        submitEmailBtn.disabled = false;
        setAlertMessage(undefined);
      }
    }
  
    const extensionNumberValidator = (e) => {
       let enteredExtensionNumber = "";
       if (e && e.target && e.target.value) enteredExtensionNumber = e.target.value;
       var numAndCommaRegex = /^[0-9.,]+$/;
       let passcodeBtn =  document.getElementById('passcodebutton');
       if (enteredExtensionNumber && !numAndCommaRegex.test(enteredExtensionNumber)) {
        passcodeBtn.disabled = true;
         setExtensionAlertMessage("Invalid Format: Extension number cannot \u2003\u2003\u2003include any letters or special characters.");
       } else {
        passcodeBtn.disabled = false;
        setExtensionAlertMessage(undefined);
       }
     }
     
    //email enrollment
    if (props === 'email-enrollment') {
        var emailId = ""
        if (userInfo && userInfo.data.email) {
            emailId = userInfo.data.email
        }
        return (
            <div style={{ classes }} data-testid="email-enrollment" className='forgot-username'>
                <form onSubmit={handleSubmitEmailWithoutOTP} data-se="o-form" slot="content" id="form_username_email" className="o-form forgot-username o-form-edit-mode">
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <h2 data-se="o-form-head" className="okta-form-title o-form-head">Account recovery enrollment</h2>
                        <div>
                            <br></br>
                            <p id="custom-title-txt">For your security, we now require two account recovery methods.</p>
                            <p id="forgot-password-title-info">To begin, please confirm or update your email<br />address:</p>
                            {/*<hr id="forgot-password-title-hr" />*/}
                        </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-label" className="okta-form-label o-form-label">
                                    <label id="forgot-username">Email address</label>
                                </div>
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                    <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                                        <input type="text" placeholder="Enter email address" defaultValue={emailId} name="emailAddress" id="enrollment-emailId"  onBlur={emailValidator} onFocus={emailValidator}/>
                                    </span>
                                    {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                                </div>
                            </div>
                            <button id="submitEmail" data-se="email-button" className="button button-primary button-wide email-button" href="#" >Submit email</button>
                        </div>
                    </div>
                </form>
                <div className="auth-footer" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in</a></div>
                {userInfo && userInfo.data.UIBypassCounter < 3 && <div id="skipAccountRecovery" name="skipAccountRecovery" className="auth-footer" onClick={handleBackSkipEnrollment}><a id="skipAccountRecovery" className="link help js-back" data-se="back-link">Skip account recovery setup</a></div>}
            </div>
        )
    }
    // phone enrollment
    if (props === 'phone-enrollment') {
        return (
            <div style={{ classes }}  className='forgot-username'>
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                        <h2 data-se="o-form-head" className="okta-form-title o-form-head">Account recovery enrollment</h2>
                        {errorMessage && <div className="okta-form-infobox-error infobox infobox-error" role="alert">
                            <span className="icon error-16"></span><p>{errorMessage}</p>
                        </div>}
                        <div>
                            <br></br>
                            <span id="custom-title-txt">Account recovery setup</span>
                            <p id="custom-title-info">Choose a second authentication method to recover  <br />your account.</p>
                        </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <div>
                                <br></br>
                                <span id="forgot-password-title-txt-small">Recovery via text message (SMS)</span>
                                <p className="okta-form-subtitle o-form-explain" data-se="o-form-explain">Enter a one time passcode sent to your mobile device.</p>
                                <button onClick={() =>{ history.push({pathname: "/setup-phone-enrollment",  search: "sms",  state: { type: 'sms' } })}} data-se="email-button" className="button button-primary button-wide email-button" href="#">Set up text message</button>
                               <br></br>
                            </div>
                            <br></br>
                            <br></br>
                            <div  className="phone-enrollment">
                                <span id="forgot-password-title-txt-small">Recovery via voice call</span>
                                <p className="okta-form-subtitle o-form-explain" data-se="o-form-explain">Use a phone to authenticate by following voice instructions.</p>
                                <button onClick={() =>{history.push({pathname: "/setup-phone-enrollment", search:"call",  state: { type: 'call' } })}} data-se="email-button" className="button button-primary button-wide email-button " href="#" >Set up voice call</button>
                               <br></br>
                            </div>
                        </div>
                    </div>
                <div className="auth-footer" onClick={handleBack}><a href="/signin" className="link help js-back" data-se="back-link">Back to sign in</a></div>
                {userInfo && userInfo.data.UIBypassCounter < 3 && <div className="auth-footer" onClick={handleBackSkipEnrollment}><a className="link help js-back" data-se="back-link">Skip account recovery setup</a></div>}
            </div>
        );
    }
    // setup phone enrollment
    if (location && location.pathname.includes('setup-phone-enrollment')) {
        if(document.getElementById("footer-container-div") !== null) {
            document.getElementById('footer-container-div').className = 'footer-container-relative';
        }
        const enrollmentType = location.search.replace("?","");
        let accountEnrollmentTypeHeader = ''
        let accountEnrollmentTypeInfo = ''
        let customParaTip = 'The number above is associated with your account, please update if number is incorrect.'
        if (enrollmentType === 'sms') {
            accountEnrollmentTypeHeader = 'Text message authentication'
            accountEnrollmentTypeInfo = 'Please follow instruction below to complete your text message authentication.'
        } else if (enrollmentType === 'call') {
            accountEnrollmentTypeHeader = 'Voice call authentication'
            accountEnrollmentTypeInfo = 'Please follow instruction below to complete your voice call authentication.'
            customParaTip = 'Tip: you can use commas before and after the extension to match the timing of your phone tree prompts.'
        }
        var country = "us"
        let phoneNumber = ""
        let extensionNumber = ""
        if (userInfo && userInfo.data) {
            if (userInfo.data.hasOwnProperty('mobile') || userInfo.data.hasOwnProperty('phone_number')) {
                enrollmentType === 'call' ? phoneNumber = userInfo.data.phone_number : phoneNumber = userInfo.data.mobile
                enrollmentType === 'sms' ? phoneNumber = userInfo.data.mobile : phoneNumber = userInfo.data.phone_number
                if (phoneNumber && phoneNumber.length > 10 && phoneNumber.startsWith("91")) {
                    country = "in"
                }
                if (phoneNumber && phoneNumber.length > 10 && phoneNumber.startsWith("63")) {
                    country = "ph"
                }
            }          
        }
        if(phoneNumber) {
        const splitPhoneNumber = phoneNumber.split(new RegExp('x', 'i'))
        if(splitPhoneNumber.length > 1) extensionNumber = splitPhoneNumber[1]
        phoneNumber = (splitPhoneNumber[0].replace(/[^\d\+]/g, "").slice(-10))
        }
        return (
            <div style={{ classes }} data-testid="setup-phone-enrollment" className='forgot-username'>
                <form onSubmit={verifyAndActivateFactorAPICall(enrollmentType)} data-se="o-form" slot="content" id="form_username" className="o-form forgot-username o-form-edit-mode">
                    <div data-se="o-form-content" className="o-form-content o-form-theme clearfix">
                    <h2 data-se="o-form-head" className="okta-form-title o-form-head">Text message authentication</h2>
                     {errorMessage && <div className="okta-form-infobox-error infobox infobox-error" role="alert">
                            <span className="icon error-16"></span><p>{errorMessage}</p>
                        </div>}
                        <div>
                            <br></br>
                            <span id="custom-title-txt">{accountEnrollmentTypeHeader}</span>
                            <p id="custom-title-info">{accountEnrollmentTypeInfo}</p>
                            <hr id="custom-title-hr" />
                        </div>
                        <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                            <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top">
                                    <label name="country" id="forgot-username">Country</label>
                                </div>
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                    <div className="inputWidth">
                                        <PhoneInput
                                            type='tel' className='operations-supplierCapacity'
                                            name="countryName" aria-label=""
                                            aria-describedby="input-container-error38"
                                            aria-invalid="true" country={country} onlyCountries={envConfig.COUNTRIES_ALLOWED}
                                            >
                                        </PhoneInput>
                                    </div>
                                    {alertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{alertMessage}</p>}
                                </div>
                            </div>
                            <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                 {phoneNumberAlertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{phoneNumberAlertMessage}</p>}
                                        <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top">
                                <label id="forgot-username">Phone Number</label>
                                </div>
                                    <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field-customUi input-fix o-form-has-errors">
                                        <input placeholder="" id="dialCode"
                                            type='text' className='operations-supplierCapacity'
                                            name="dialCode" aria-label=""
                                            aria-describedby="input-container-error38"
                                            aria-invalid="true" disabled
                                        />
                                    </span>
                                     <span id="inputPhoneNumber" data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field-customUi-phone input-fix o-form-has-errors">
                                        <Phone
                                            type='tel' className='operations-supplierCapacity'
                                            name="phoneNumber" aria-label="" placeholder="(&nbsp;&nbsp;&nbsp;) ___-____"
                                            aria-describedby="input-container-error38"
                                            aria-invalid="true" value={phoneNumber}>
                                        </Phone>
                                    </span>
                                </div>
                                { enrollmentType === 'call' && 
                                <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                <div data-se="o-form-label" className="okta-form-label form-label-without-margin-top">
                                    <label id="forgot-username">Extension Number</label>
                                </div>
                                <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                    <span data-se="o-form-input-username" className="o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors">
                                        <input type="text" placeholder= 'Enter extension number' id="extensionNumber"  name="extensionNumber" onBlur={extensionNumberValidator} onFocus={extensionNumberValidator} defaultValue = {extensionNumber}/>
                                    </span>
                                    {extensionAlertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                        <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{extensionAlertMessage}</p>}
                                </div>
                                </div>}
                                <div id="passcodehideshow">
                                {!extensionAlertMessage && <p id="custom-para-p">{customParaTip}{(enrollmentType === 'call') && <a href="https://www.cardinalhealth.com/en/cmp/un/rp/login-help-resources.html" className="link help js-back" data-se="back-link" target="_blank">Learn more</a>}</p>}
                                    <hr id="custom-title-hr" />
                                </div>
                                <div data-se="o-form-fieldset" className="o-form-fieldset o-form-label-top">
                                    <div data-se="o-form-input-container" className="o-form-input o-form-has-errors">
                                        <span data-se="o-form-input-username" id="passcodeInputbox" className="o-form-input-name-username o-form-control okta-form-input-field-custompasscode input-fix o-form-has-errors">
                                        {passCodeAlertMessage && <p id="input-container-error38" className="okta-form-input-error o-form-input-error o-form-explain" role="alert">
                                                <span className="icon icon-16 error-16-small" role="img" aria-label="Error"></span>{passCodeAlertMessage}</p>}
                                            <div data-se="o-form-label" className="okta-form-label o-form-label">
                                                <label id="forgot-username">Passcode</label>
                                            </div>
                                            <input type="number" pattern="[0-9]" placeholder= "Enter passcode" name="passcode" id="inputPasscode" aria-label="" className='o-form-input-name-username o-form-control okta-form-input-field input-fix o-form-has-errors' aria-describedby="input-container-error38" aria-invalid="true" onKeyDown={clearPasscodeAlert}/>
                                            <div data-se="o-form-label" className="o-form-label"><label id="dummy-username1"></label></div>
                                            <hr id="custom-title-hr" />
                                            <div>
                                                <button data-se="email-button" className="button button-primary button-wide email-button">Verify Passcode</button>
                                                <button data-se="email-button" id='resendPasscode' onClick={resendPassCode(enrollmentType)} className="button button-primary button-wide email-button link-button-disabled" >Resend Passcode</button>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <button data-se="email-button" name='passcodebutton' id='passcodebutton' onClick={sendPasscodeAPICall(enrollmentType)} className="button button-primary button-wide email-button">Send passcode</button>
                         </div>
                    </div>
                </form>
                <div id="brDiv">
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                </div>
                <div className="auth-footer"><a href="/phone-enrollment" className="link help js-back" data-se="back-link">Choose a different authentication method</a></div>
            </div>
        )

    }
    // enrollment success 
    if(location && location.pathname.includes('enrolled')) {
        const enrolledFactorType = location.search.replace("?","")
        console.log('Enrolled Successfully for factor ' + enrolledFactorType)
      let enrolledFactor = ''
      if (enrolledFactorType === 'sms') enrolledFactor = 'mobile number';
      if (enrolledFactorType === 'call') enrolledFactor = 'phone number';
      if (enrolledFactorType === 'email') enrolledFactor = 'email address';
      return (
        <div style={{ classes }} className='forgot-username'>
            <div>
                <br></br>
                <span id="custom-title-txt">You're all set!</span>
                <p id="custom-title-info">Your {enrolledFactor} will now be used to recover <br /> your account.</p>
                <br></br>
                <div className="custom-para">
                <p id="custom-title-info">To add or modify your account recovery <br />methods, go to "Update account recovery" <br />under your personal settings.</p>
                <br></br>
                </div>
            </div>
            <div className="o-form-fieldset-container" data-se="o-form-fieldset-container">
                <button data-se="email-button" onClick={orderExpress} className="button button-primary button-wide email-button" href="#" >Go to Order Express</button>
            </div>
        </div>
    )
    }
    return null;
}
export default EnrollmentForm;
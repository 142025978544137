import {envConfig} from "../config";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
var js2xmlparser = require("js2xmlparser");

export const coexistenceRequest = async(user, token) => {
   const loginRequest  = {
      userName: user,
      password: token,
      action: 'GET'
  }
  const xmlRequestBody = js2xmlparser.parse('loginRequest', loginRequest);
   try{
    const headerParams = {
      Accept: "application/json",
      "Content-Type": "application/xml"
      };
    const actualResponse = await axios.post(envConfig.COEXIST_ENDPOINT,
        xmlRequestBody,
       {headers: headerParams}
      )
    return actualResponse;
    } catch(err) {
    console.log('Error in Coexistence Request API call ' + err)
    return false;
  }

}

const getOAuthToken = async() => {
  try{
    const oauthres = await axios.post(envConfig.OAUTH_TOKEN_URL,{},{headers: { Authorization: envConfig.OAUTH_BASIC_AUTH, "Content-Type": "application/json" }})
    const accessToken = oauthres.data.access_token;
    return accessToken;
  } catch(err) {
    console.log('Error in getting Custom Email OAuth Token' + err);
    return false;
  }
}

const getHeaderParamsWithAccessToken = () => {
  const user_id = JSON.parse(localStorage.getItem("okta-token-storage"));
  let accessToken = "";
  let sub = "";
  if (user_id && user_id.accessToken && user_id.accessToken.accessToken)
    accessToken = user_id.accessToken.accessToken;
  if (user_id && user_id.accessToken && user_id.accessToken.claims)
    sub = user_id.accessToken.claims.uid;
  return {
    sub: sub,
    header: {
    Authorization: "Bearer " + accessToken,
    Accept: "application/json",
    "Content-Type": "application/json",
  }};
}

export const incrementUIBypassCounter =  async(counter) =>{
 const headerAndSub = getHeaderParamsWithAccessToken();
 try {
   const actualResponse = await axios.post(envConfig.OKTA_BASE_URL + "/api/v1/users/me",
     {
       profile: {UIBypassCounter: counter},
     },
     { headers: headerAndSub.header }
   );
   return actualResponse;
 } catch (err) {
   console.error("Error in incrementUIBypassCounter API call " + err);
   return false;
 }
}

export const updateEmailInProfile = async (email) => {
const headerAndSub = getHeaderParamsWithAccessToken()
  try {
   const actualResponse = await axios.post(envConfig.OKTA_BASE_URL + '/api/v1/users/me',
      {
        "profile": {
          "email": email,
          "secondEmail": email
        }
      },
      { headers: headerAndSub.header }
    )
    return actualResponse;
  } catch (err) {
    console.error('Error in updateEmailInProfile API call ' + err)
    return false;
  }
};

export const updatePhoneInProfile = async (formatedPhoneNumber, type) => {
  let profileBody = {}
  if (type === 'sms') {
    profileBody.mobilePhone = formatedPhoneNumber
  } else if (type === 'call') {
    profileBody.primaryPhone = formatedPhoneNumber
  }
  const headerAndSub = getHeaderParamsWithAccessToken()
    try {
      return await axios.post(envConfig.OKTA_BASE_URL + '/api/v1/users/me',
        {
          "profile": profileBody
        },
        { headers: headerAndSub.header }
      )   
    } catch (err) {
      console.error('Error in UpdatePhoneInProfile API call ' + err)
      return false;
    }
  };


export const enrollEmailWithoutOTP =  async(email) =>{
  console.log('Email Received ' + email)
  const headerAndSub = getHeaderParamsWithAccessToken();
  if(headerAndSub.sub){
    try {
      const actualResponse = await axios.post(envConfig.OKTA_BASE_URL + '/api/v1/users/' + headerAndSub.sub + '/factors?activate=true',
        {
          "factorType": "email",
          "provider": "OKTA",
          "profile": {
            "email": email,
          }
        },
        { headers: headerAndSub.header }
      )
      console.log("Enroll Email API Response ", actualResponse)
      return actualResponse;
    } catch (err) {
      console.error('Error in EnrollEmailWithoutOTP API call ' + err)
      return false;
    }
  } else {
    console.error('Sub Id not available, hence not calling the enrollEmailWithoutOTP API');
    return false;
  }
}

export const getUserInfoDetails = async() => {
  const user_id = JSON.parse(localStorage.getItem('okta-token-storage'));
  let accessToken = '';
  if(user_id && user_id.accessToken && user_id.accessToken.accessToken) 
    accessToken = user_id.accessToken.accessToken
  if(accessToken){
    try{
      const headerParams = {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
        };
      const actualResponse = await axios.get(envConfig.USER_INFO_ENDPOINT,
         {headers: headerParams}
        )
        return (actualResponse);
      }catch(err) {
      console.log('Error in getUserInfo API call ' + err)
      return false;
    }
  } else {
    console.error('Access Token not available, hence not calling GetUserInfoDetails API');
    return false;
  }
};

export const getFactorsEnrolled = async() => {
  const headerAndSub = getHeaderParamsWithAccessToken();
  if(headerAndSub.sub) {
    try {
      const actualResponse = await axios.get(
        envConfig.OKTA_BASE_URL + "/api/v1/users/" + headerAndSub.sub + "/factors",
        { headers: headerAndSub.header }
      );
      return actualResponse;
    } catch (err) {
      console.error("Error in getFactorsEnrolled API call " + err);
      return false;
    }
  } else {
    console.error('Sub Id not available, hence not calling the FactorsEnrolled API');
    return false;
  }
};

export const sendPasscodeViaSMSOrCall = async(formatedPhoneNumber, extensionNumber, factorType) =>{
  let profileBody = {}
  if (factorType === 'sms') {
    profileBody.phoneNumber = formatedPhoneNumber
  } else if (factorType === 'call') {
    profileBody.phoneNumber = formatedPhoneNumber
    if(extensionNumber.length > 0 ){
      profileBody.phoneExtension = extensionNumber
    }
  }
  const headerAndSub = getHeaderParamsWithAccessToken();
  if(headerAndSub.sub && headerAndSub.sub !== "") {
    try {
      const actualResponse = await axios.post(envConfig.OKTA_BASE_URL + '/api/v1/users/' + headerAndSub.sub + '/factors?updatePhone=true',
        {
          "factorType": factorType,
          "provider": "OKTA",
          "profile": profileBody
          },
        { headers: headerAndSub.header }
      )
      return actualResponse;
    } catch (err) {
      console.error('Error in sendPasscodeViaSMSOrCall API call ' + err)
      return false;
    }
  } else {
    console.error('Sub Id not available, hence not calling the SendPasscodeViaSMSOrCall API');
    return false;
  }
  
}

export const verifyAndActivateFactorAPI = async(passcode,factorId) => {
  const headerAndSub = getHeaderParamsWithAccessToken();
  if(headerAndSub.sub && headerAndSub.sub !== "") {
    try {
      const actualResponse = await axios.post(envConfig.OKTA_BASE_URL + '/api/v1/users/' + headerAndSub.sub + '/factors/' + factorId + '/lifecycle/activate',
        {
          "passCode": passcode
        },
        { headers: headerAndSub.header }
      )
      return actualResponse;
    } catch (err) {
      console.error('Error in Activate Factor API call ' + err);
      return false;
    }
  } else {
    console.error('Sub Id not available, hence not calling the verifyAndActivateFactorAPI');
    return false;
  }
};

export const resendCode  = async(phone, factorId, factorType) => {
  const headerAndSub = getHeaderParamsWithAccessToken();
   try{
       const actualResponse = await axios.post(envConfig.OKTA_BASE_URL + '/api/v1/users/' + headerAndSub.sub + '/factors/' + factorId + '/resend',
        {
          "factorType": factorType,
          "provider": "OKTA",
          "profile": {
            "phoneNumber": phone
          }             
        },
         {headers: headerAndSub.header}
        )
      return actualResponse;
      }catch(err) {
      console.error('Error in resendCode API call ' + err)
      return false;
    }
};
/**Forgot Password via Email.
 * Send API call to retrieve recovery token.*/
 export const passwordRecoveryViaEmail = async(user) => {
  const accessToken = await getOAuthToken();
  if(accessToken){
    try{
      const headerParams = {
        UUID: uuidv4(),
        TimeStamp: new Date().toISOString().replace('T',' '),
        Authorization: "Bearer " + accessToken,
        "x-api-key": envConfig.CUSTOM_EMAIL_API_KEY,
        "Content-Type": "application/json",
        };
      const actualResponse = await axios.post(envConfig.CUSTOM_EMAIL_ENDPOINT,
         { userName: user,
            action: "PasswordRecoveryEmail",
            communicationType: "email",
            source: "SPA"
         },
         {headers: headerParams}
        )
      return (actualResponse.status === 200 || actualResponse.status === 201) ? true : false ;
    } catch(err) {
      console.error('Error in PasswordRecoveryViaEmail API call ' + err)
      return false;
    }
  } else {
      console.error('Error in getting Custom Email OAuth Token.');
      return false;
    }
};

/**Account Unlock via Email.
 * Send API call to retrieve recovery token.*/
 export const accountUnlockViaEmail = async(user) => {
    const accessToken = await getOAuthToken();
    if(accessToken){
      try{
        const headerParams = {
          UUID: uuidv4(),
          TimeStamp: new Date().toISOString().replace('T',' '),
          Authorization: "Bearer " + accessToken,
          "x-api-key": envConfig.CUSTOM_EMAIL_API_KEY,
          "Content-Type": "application/json",
          };
        const actualResponse = await axios.post(envConfig.CUSTOM_EMAIL_ENDPOINT,
           {  userName: user,
              action: "UnlockAccountEmail",
              communicationType: "email",
              source: "SPA"
           },
           {headers: headerParams}
          )
        return (actualResponse.status === 200 || actualResponse.status === 201) ? true : false ;
        }catch(err) {
        console.error('Error in AccountUnlockViaEmail API call ' + err)
        return false;
      }
    } else {
        console.error('Error in getting Custom Email OAuth Token.');
        return false;
      }
  };

/** Forgot Username via Email.
 * Send API call to filter based on email.
 * Limit to active users only, and return up to 10
 */
export const retrieveUsername = async(emailId) => {
  const accessToken = await getOAuthToken();
  if (accessToken) {
    try {
      const headerParams = {
        UUID: uuidv4(),
        TimeStamp: new Date().toISOString().replace('T', ' '),
        Authorization: "Bearer " + accessToken,
        "x-api-key": envConfig.CUSTOM_EMAIL_API_KEY,
        "Content-Type": "application/json",
      };
  const actualResponse = await axios.post(envConfig.CUSTOM_EMAIL_ENDPOINT, {
            emailID: emailId,
            action: "UserNameRecoveryEmail",
            communicationType: "email",
            source: "SPA"
          },
          {headers: headerParams}
        );
  return (actualResponse.status === 200 || actualResponse.status === 201) ? true : false ;
  }catch(err) {
  console.error('Error in Retrieve Username API call ' + err)
  return false;
}
} else {
  console.error('Error in getting Custom Email OAuth Token.');
  return false;
}
};

/** Reactivate Account via Email.
 * Send API call to search user in Provisioned state. (search is not used for critical flow as data as search may not reflect the latest information)
 * Send API call to reactivate the account.
*/

export const reactivateAccount = async(user) => {
  const accessToken = await getOAuthToken();
  if (accessToken) {
    try {
      const headerParams = {
        UUID: uuidv4(),
        TimeStamp: new Date().toISOString().replace('T', ' '),
        Authorization: "Bearer " + accessToken,
        "x-api-key": envConfig.CUSTOM_EMAIL_API_KEY,
        "Content-Type": "application/json",
      };
      const actualResponse = await axios.post(envConfig.CUSTOM_EMAIL_ENDPOINT,
        {
          userName: user,
          action: "ReactivateAccountEmail",
          communicationType: "email",
          source: "SPA"
        },
        {headers: headerParams}
        );
    return (actualResponse.status === 200 || actualResponse.status === 201) ? true : false ;
  }catch(err) {
  console.error('Error in Retrieve Username API call ' + err)
  return false;
}
} else {
  console.error('Error in getting Custom Email OAuth Token.');
  return false;
}
  
};
/*
PasswordResetConfirmationEmail
*/

export const resetPasswordConfirmationEmail = async(oktaUserInfoObj) => {
  const accessToken = await getOAuthToken();
  if (accessToken) {
    try {
      const headerParams = {
        UUID: uuidv4(),
        TimeStamp: new Date().toISOString().replace('T', ' '),
        Authorization: "Bearer " + accessToken,
        "x-api-key": envConfig.CUSTOM_EMAIL_API_KEY,
        "Content-Type": "application/json",
      };
      const actualResponse = await axios.post(envConfig.CUSTOM_EMAIL_ENDPOINT,
        {
          userName: oktaUserInfoObj.preferred_username, // name?
          firstName: oktaUserInfoObj.given_name,
          lastName: oktaUserInfoObj.family_name,
          emailID: oktaUserInfoObj.email,
          action: "PasswordResetConfirmationEmail",
          communicationType: "email",
          source: "SPA"
        }
        ,
        {headers: headerParams}
        );
    return (actualResponse.status === 200 || actualResponse.status === 201) ? true : false ;
  }catch(err) {
  console.error('Error in Reset Password Confirmation Email API call ' + err)
  return false;
}
} else {
  console.error('Error in getting Custom Email OAuth Token.')
  return false;
}
}

export const accountUnlockConfirmationEmail = async(userId) => {
  const accessToken = await getOAuthToken();
  if (accessToken) {
    try {
      const headerParams = {
        UUID: uuidv4(),
        TimeStamp: new Date().toISOString().replace('T', ' '),
        Authorization: "Bearer " + accessToken,
        "x-api-key": envConfig.CUSTOM_EMAIL_API_KEY,
        "Content-Type": "application/json",
      };
      const actualResponse = await axios.post(envConfig.CUSTOM_EMAIL_ENDPOINT,
        {
          userName: userId,
          action: "UnlockAccountSuccessEmail",
          communicationType: "email",
          source: "SPA"
        }
        ,
        {headers: headerParams}
        );
    return (actualResponse.status === 200 || actualResponse.status === 201) ? true : false ;
  } catch(err) {
  console.error('Error in Account Unlock Confirmation Email API call ' + err)
  return false;
}
} else {
  console.error('Error in getting Custom Email OAuth Token.')
  return false;
}
}

//sesions/me okta API gets Session information for the current user.
export const getSessionsResponse =  async() =>{
  const headerParams = {
    Accept: "application/json"
    };
  try {
    const actualResponse = await axios.get(envConfig.OKTA_BASE_URL + "/api/v1/sessions/me", { withCredentials: true },
      { headers: headerParams });
    return actualResponse;
  } catch (err) {
    console.error("Error in getSessionsResponse API call " + err);
    return false;
  }
 }